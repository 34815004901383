import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import PostTags from '../components/PostTags/PostTags';
import config from '../../data/SiteConfig';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../css/main.scss';
import { Container } from '@material-ui/core';
import { deserialize } from 'react-serialize';

export default function PostTemplate({ data }) {
  let postNode = data.markdownRemark;
  let post = postNode.frontmatter;
  let image = getImage(post.featuredImage?.childImageSharp);
  let authorName = post.author?.name;

  return (
    <Layout>
      <Container>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitleShort}`}</title>
          {post.summary && <meta name="description" content={post.summary} />}
        </Helmet>
        {/*<MarkdownSeo postNode={postNode} url={url} state={blogType}/>*/}
        <div>
          <div className="legacy">
            <h1 style={{ marginTop: '10rem' }} className="title">
              {post.title}
            </h1>
            <GatsbyImage image={image} alt={post.title} />
            <div className="post-content">
              {postNode.html ? (
                <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
              ) : postNode.content ? (
                deserialize(postNode.content)
              ) : null}
            </div>
            <div className="post-meta">
              <PostTags tags={post.tags} />
              <div className="author-info">
                <h3>{authorName}</h3>
                <p>{post.date}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        summary
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        date(formatString: "MMMM Do, YYYY")
        author {
          name
        }
      }
      fields {
        slug
      }
      id
    }
  }
`;
/*
firestorePosts(id: { eq: $id }) {
      content
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        author {
          name
        }
      }
      fields {
        slug
      }
      id
    }
 */
